.DateInput_input {
    background-color: #F2F2F2;

}

.DateRangePickerInput__withBorder {
    border: none;
    border-radius: 12px;
    background-color: #F2F2F2;
}

#start_date_id {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;

}


.DateInput__small_2 {
    border-radius: 12px;
}

.DateInput_input_1::placeholder {
    font-size: 14px;
    color: #6C727F;
    font-weight: 500;
}

.DateInput_input_1 {
    font-size: 16px;
    font-weight: bold;
}

.DateRangePicker_picker__directionLeft{
left:-30px !important;
}