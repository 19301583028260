@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@400;500;600;700&family=Mulish:wght@400;500;600;700&family=Archivo:wght@400;500;600;700&family=Archivo:wght@400;500;600;700&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    .display-initial {
      display: initial;
    }
  }


html,
body {
  max-width: 1920px;
  margin: 0 auto;
}


* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }